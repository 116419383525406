var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row justify-content-md-center"
  }, [_c('div', {
    staticClass: "col-xxl-4 col-xl-5 col-lg-7 col-md-7"
  }, [_c('div', {
    staticClass: "changepsw-card"
  }, [_vm._m(0), _c('div', {
    staticClass: "changepsw-form"
  }, [_c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.changePassword);
            }
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "name": "Current Password",
            "vid": "old_password"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var valid = _ref2.valid,
                  errors = _ref2.errors;
              return _c('b-form-group', {
                staticClass: "change-pwd"
              }, [_c('label', [_c('span', [_vm._v(" Current Password "), _c('span', {
                staticClass: "input-required"
              }, [_vm._v("*")])])]), _c('div', {
                staticClass: "changeForm-group",
                class: errors[0] || valid ? 'errorIcon_eye' : ''
              }, [_c('b-form-input', {
                staticClass: "changepsw-field",
                attrs: {
                  "id": "old_password",
                  "type": "password",
                  "state": errors[0] ? false : valid ? true : null,
                  "placeholder": "Enter Current Password"
                },
                model: {
                  value: _vm.form.old_password,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "old_password", $$v);
                  },
                  expression: "form.old_password"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(errors[0]) + " ")])], 1)]);
            }
          }], null, true)
        }), _c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "name": "New Password",
            "vid": "new_password"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var valid = _ref3.valid,
                  errors = _ref3.errors;
              return _c('b-form-group', {}, [_c('label', [_c('span', [_vm._v(" New Password "), _c('span', {
                staticClass: "input-required"
              }, [_vm._v("*")])])]), _c('div', {
                staticClass: "changeForm-group",
                class: errors[0] || valid ? 'errorIcon_eye' : ''
              }, [_c('b-form-input', {
                staticClass: "changepsw-field",
                attrs: {
                  "id": "new_password",
                  "type": "password",
                  "state": errors[0] ? false : valid ? true : null,
                  "placeholder": "Enter New Password"
                },
                model: {
                  value: _vm.form.new_password,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "new_password", $$v);
                  },
                  expression: "form.new_password"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(errors[0]) + " ")])], 1)]);
            }
          }], null, true)
        }), _c('div', [_c('password-strength-bar', {
          attrs: {
            "password": _vm.form.new_password
          }
        })], 1), _c('ValidationProvider', {
          attrs: {
            "rules": "required|confirmed:new_password",
            "name": "Confirm Password"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var valid = _ref4.valid,
                  errors = _ref4.errors;
              return _c('b-form-group', {}, [_c('label', [_c('span', [_vm._v(" Confirm Password "), _c('span', {
                staticClass: "input-required"
              }, [_vm._v("*")])])]), _c('div', {
                staticClass: "changeForm-group errorIcon_eye"
              }, [_c('b-form-input', {
                staticClass: "changepsw-field",
                attrs: {
                  "type": "password",
                  "id": "confirm_password",
                  "state": errors[0] ? false : valid ? true : null,
                  "placeholder": "Enter Confirmation Password"
                },
                model: {
                  value: _vm.confirmPassword,
                  callback: function callback($$v) {
                    _vm.confirmPassword = $$v;
                  },
                  expression: "confirmPassword"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(errors[0]) + " ")])], 1)]);
            }
          }], null, true)
        }), _c('div', [_vm.errorText ? _c('span', {
          staticClass: "password_error"
        }, [_vm._v(" " + _vm._s(_vm.errorText) + " ")]) : _vm._e()]), _c('div', {
          staticClass: "change-pwd-submit-btn"
        }, [_c('button', {
          staticClass: "univ-btn",
          attrs: {
            "type": "submit"
          }
        }, [_vm._v(" " + _vm._s(_vm.submit ? "Changing..." : "Submit") + " ")])])], 1)];
      }
    }])
  })], 1)])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "changepsw-head mb-4"
  }, [_c('div', {
    staticClass: "changepsw-icon"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/password.svg"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "changepsw-title"
  }, [_c('h1', [_vm._v("Change Password")]), _c('p', [_vm._v("Please Confirm your current password")])])]);
}]

export { render, staticRenderFns }