<template>
  <div class="page-wrapper">
    <div class="container-fluid">
      <div class="row justify-content-md-center">
        <div class="col-xxl-4 col-xl-5 col-lg-7 col-md-7">
          <div class="changepsw-card">
            <div class="changepsw-head mb-4">
              <div class="changepsw-icon">
                <img src="@/assets/images/password.svg" alt="" />
              </div>
              <div class="changepsw-title">
                <h1>Change Password</h1>
                <p>Please Confirm your current password</p>
              </div>
            </div>
            <div class="changepsw-form">
              <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.prevent="handleSubmit(changePassword)">
                  <ValidationProvider
                    rules="required"
                    name="Current Password"
                    vid="old_password"
                  >
                    <b-form-group slot-scope="{ valid, errors }" class="change-pwd">
                      <label>
                        <span>
                          Current Password <span class="input-required">*</span>
                        </span>
                      </label>
                      <div
                        class="changeForm-group"
                        :class="errors[0] || valid ? 'errorIcon_eye' : ''"
                      >
                        <b-form-input
                          id="old_password"
                          type="password"
                          v-model="form.old_password"
                          :state="errors[0] ? false : valid ? true : null"
                          placeholder="Enter Current Password"
                          class="changepsw-field"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </div>
                    </b-form-group>
                  </ValidationProvider>

                  <ValidationProvider
                    rules="required"
                    name="New Password"
                    vid="new_password"
                  >
                    <b-form-group slot-scope="{ valid, errors }">
                      <label>
                        <span>
                          New Password <span class="input-required">*</span>
                        </span>
                      </label>
                      <div
                        class="changeForm-group"
                        :class="errors[0] || valid ? 'errorIcon_eye' : ''"
                      >
                        <b-form-input
                          id="new_password"
                          type="password"
                          v-model="form.new_password"
                          :state="errors[0] ? false : valid ? true : null"
                          placeholder="Enter New Password"
                          class="changepsw-field"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <div>
                    <password-strength-bar :password="form.new_password">
                    </password-strength-bar>
                  </div>
                  <ValidationProvider
                    rules="required|confirmed:new_password"
                    name="Confirm Password"
                  >
                    <b-form-group slot-scope="{ valid, errors }">
                      <label>
                        <span>
                          Confirm Password <span class="input-required">*</span>
                        </span>
                      </label>
                      <div class="changeForm-group errorIcon_eye">
                        <b-form-input
                          type="password"
                          id="confirm_password"
                          v-model="confirmPassword"
                          :state="errors[0] ? false : valid ? true : null"
                          placeholder="Enter Confirmation Password"
                          class="changepsw-field"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <div>
                    <span v-if="errorText" class="password_error">
                      {{ errorText }}
                    </span>
                  </div>
                  <div class="change-pwd-submit-btn">
                    <button type="submit" class="univ-btn">
                      {{ submit ? "Changing..." : "Submit" }}
                    </button>
                    </div>
                </b-form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
export default {
  name: "ChangePassword",
  data: () => ({
    form: {
      old_password: "",
      new_password: "",
    },
    confirmPassword: "",
    placeholder: "",
    submit: false,
    errorText: "",
  }),
  methods: {
    changePassword() {
      let url = "v1/change-password";
      this.submit = true;
      Api.post(url, this.form)
        .then((res) => {
          let response = res.data.message;
          this.$notify(response);
          this.$router.push({ name: "Logout" });
        })
        .catch((err) => {
            console.log("err",err)
          this.$notify(err.response.data.message, "danger");
          this.errorText = err.response.data.message;
          setTimeout(() => {
            this.errorText = "";
          }, 2000);
        })
        .finally(() => {
          this.submit = false;
        });
    },
  },
};
</script>
<style src="@/assets/css/changepassword.css" scoped></style>

